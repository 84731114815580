/* 1 */

@font-face {
  font-family: "Sofia";
  src: local("Sofia"), url(./assets/Fonts/Sofia.ttf) format("opentype");
}

/* 2 */

@font-face {
  font-family: "Blacksword";
  src: local("Blacksword"),
    url(./assets/Fonts/Blacksword.otf) format("opentype");
}

/* 3 */

@font-face {
  font-family: "Script";
  src: local("Script"), url(./assets/Fonts/Script.ttf) format("opentype");
}

/* 4 */

@font-face {
  font-family: "Vladimir";
  src: local("Vladimir"), url(./assets/Fonts/Vladimir.ttf) format("opentype");
}

/* 5 */
@font-face {
  font-family: "Magneto";
  src: local("Magneto"), url(./assets/Fonts/Magneto.ttf) format("opentype");
}

/* 6 */

@font-face {
  font-family: "Brushsci";
  src: local("Brushsci"), url(./assets/Fonts/brushsci.ttf) format("opentype");
}

/* 7 */

@font-face {
  font-family: "Edwardian";
  src: local("Edwardian"), url(./assets/Fonts/Edwardian.ttf) format("opentype");
}

/* 8 */

@font-face {
  font-family: "Embassy";
  src: local("Embassy"), url(./assets/Fonts/Embassy.ttf) format("opentype");
}

/* 9 */

@font-face {
  font-family: "Fave";
  src: local("Fave"), url(./assets/Fonts/Fave.ttf) format("opentype");
}

/* 10 */

@font-face {
  font-family: "FrenchScriptMT";
  src: local("FrenchScriptMT"), url(./assets/Fonts/FrenchScriptMT.ttf) format("opentype");
}

/* 11 */

@font-face {
  font-family: "Austria";
  src: local("Austria"), url(./assets/Fonts/Austria.ttf) format("opentype");
}

/* 12 */

@font-face {
  font-family: "Lucida";
  src: local("Lucida"), url(./assets/Fonts/Lucida.ttf) format("opentype");
}

/* 13*/
@font-face {
  font-family: "Mistral";
  src: local("Mistral"), url(./assets/Fonts/Mistral.ttf) format("opentype");
}

/* 14 */

@font-face {
  font-family: "Abundant";
  src: local("Abundant"), url(./assets/Fonts/Abundant.ttf) format("opentype");
}

/* 15 */

@font-face {
  font-family: "Mystical_Snow";
  src: local("Mystical_Snow"),
    url(./assets/Fonts/Mystical_Snow.ttf) format("opentype");
}

/* 16 */

@font-face {
  font-family: "Palace";
  src: local("Palace"), url(./assets/Fonts/Palace.ttf) format("opentype");
}

/* 17 */

@font-face {
  font-family: "Naifah";
  src: local("Naifah"), url(./assets/Fonts/Naifah.ttf) format("opentype");
}

/* 18 */

@font-face {
  font-family: "Daniella";
  src: local("Daniella"), url(./assets/Fonts/Daniella.ttf) format("opentype");
}

/* 19 */

@font-face {
  font-family: "Aprilisa";
  src: local("Aprilisa"), url(./assets/Fonts/Aprilisa.ttf) format("opentype");
}

/* 20 */

@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url(./assets/Fonts/Verdana.ttf) format("opentype");
}
